<template>
  <!-- Manage Shipping Packages Modal -->
  <div class="modal fade modal-xl modal-fullscreen" id="package_modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">{{ formData.product_sku }}</h2>
          <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" id="groupclosebutton">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <div class="modal-body">
          <el-form @submit.prevent="submitPackage()" :model="formData" ref="packageRef" class="w-100">
            <div class="row g-9">
              <div class="col-md-12 fv-row">
                <div class="mb-6">
                  <label class="fs-6 fw-bold mb-2 form-label">{{ packageConstants.SELECT_INTERNAL_PACKAGE }}</label>
                  <el-form-item>
                    <el-select v-model="formData.internal_package_id" :placeholder="packageConstants.SELECT_INTERNAL_PACKAGE" class="w-100" size="large">
                      <el-option :label="packageval.package_code" :value="packageval.master_package_id" :key="packageval.master_package_id"  v-for="(packageval, packagekey) in internalPackages"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="col-md-12 fv-row mt-0">
                <div class="mb-6">
                  <label class="fs-6 fw-bold mb-2 required form-label">{{ packageConstants.SELECT_EXTERNAL_PACKAGE }}</label>
                  <el-form-item prop="external_package_id"
                    :rules="{
                      required: true,
                      message: packageConstants.EXTERNAL_PACKAGE_REQUIRED,
                      trigger: 'blur',
                    }">
                    <el-select v-model="formData.external_package_id" :placeholder="packageConstants.SELECT_EXTERNAL_PACKAGE" class="w-100" size="large">
                      <el-option :label="packageval.package_code" :value="packageval.master_package_id" :key="packageval.master_package_id"  v-for="(packageval, packagekey) in externalPackages"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="col-md-12 fv-row mt-0">
                <div class="mb-6">
                  <label class="fs-6 fw-bold mb-2 required form-label">{{ generalConstants.STATUS }}</label>
                  <el-form-item>
                    <el-switch v-model="formData.shipping_package_status" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="submitPackage(packageRef)">{{ generalConstants.SAVE }}</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ generalConstants.CANCEL }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { notificationFire } from "@/composable/notification.js";
import { checkNumberWithoutRequired } from "@/composable/common-validation.js";
import globalConstant from "@/core/data/globalConstant.js";

export default({
  name: "add-customer-modal",
  components: {},
  props: {
    formData: Object
  },
  setup(props, {emit}) {
    const packageRef = ref(null);
    const generalConstants = globalConstant.general;
    const packageConstants = globalConstant.package;
    const internalPackages = ref([]);
    const externalPackages = ref([]);

    //Get active products details
    const getMasterPackageDetails = async() => {
      await ApiService.query("get-active-master-package")
      .then(({ data }) => {
        if(data.data) {
          let response = '';
          data.data.map(function (element) {
            if(element.package_type == '1') {
              response = externalPackages.value;
            } else {
              response = internalPackages.value;
            }
            response.push({
              'master_package_id': element.master_package_id,
              'package_type': element.package_type,
              'package_code': element.package_code
            });
          });
          props.formData.master_packages = data.data;
        }
      })
      .catch((error) => {
        let message='';
        if (typeof (error.response) !== 'undefined') {
          message = error.response.data.message;
        } else if (typeof (error.message) !== 'undefined') {
          message = error.message;
        } else {
          message = generalConstants.NOTIFY_WRONG_MESSAGE;
        }
        notificationFire(message, generalConstants.NOTIFY_ERROR);
      });
    }

    //Submit package Form
    const submitPackage = (packageRef) => {
      packageRef.validate((valid) => {
        if (!valid) return false;
        
        let addParams = {
          _method: props.formData.shipping_package_id > 0 ? "PUT" : null,
          product_id: props.formData.product_id,
          product_sku_id: props.formData.product_sku_id,
          internal_package_id: props.formData.internal_package_id,
          external_package_id: props.formData.external_package_id,
          status: props.formData.shipping_package_status
        };
        let apiURL = "shipping-packages";
        if(props.formData.shipping_package_id > 0) {
          apiURL = "shipping-packages/" + props.formData.shipping_package_id;
        }
        ApiService.post(apiURL, addParams)
        .then(({ data }) => {
          if(data) {
            notificationFire(data.message, generalConstants.NOTIFY_SUCCESS);
            document.getElementById('groupclosebutton').click();
            emit('setPackageDetails');
          }
        })
        .catch((error) => {
          let message='';
          if (typeof (error.response) !== 'undefined') {
            message = error.response.data.message;
          } else if (typeof (error.message) !== 'undefined') {
            message = error.message;
          } else {
            message = generalConstants.NOTIFY_WRONG_MESSAGE;
          }
          notificationFire(message, generalConstants.NOTIFY_ERROR);
        });
      });
    };

    onMounted( async () => {
      await getMasterPackageDetails();
    })

    return {
      submitPackage,
      packageRef,
      checkNumberWithoutRequired,
      getMasterPackageDetails,
      generalConstants,
      packageConstants,
      internalPackages,
      externalPackages
    };
  },
});
</script>
